.App {
  text-align: center;
}
.header-logo img {
  width: 200px;
}
.banner-header-wrapper {
  height: 800px;
}

.banner-header-bg {
  width: 100%;
  height: 800px;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  overflow: hidden;
}

.service-card {
  overflow: hidden;
}

.service-image-wrapper {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
}

.service-image {
  transition: transform 0.3s ease;
}

.service-card:hover .service-image {
  transform: scale(1.1);
}

.jsa-banner-container {
  height: 600px;
  position: relative;
}

.jsa-banner-content-container h1 {
  font-size: 89px;
}

.jsa-banner-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.jsa-banner-image-container img {
  width: 1000px;
  position: absolute;
  height: auto;
  right: -400px;
  top: 170px;
}

/* About us */

.aboutus-left-img-container {
  width: 100%;
  height: 550px;
}

/* contact us */

.contact-section {
  background-color: #f5f5f5;
  width: 100%;
  height: 100%;
  background-image: url("../public/7377.jpg");
}

.contact-content {
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-content h1 {
  font-size: 60px;
  text-align: center;
  font-weight: bold;
  width: 65%;
  line-height: 1.2em;
  margin-bottom: 1rem;
}
/* background-color: #00000087;
padding: 3rem 0; */

/* //////////////////////////  responsive  ////////////////////////// */
/* Responsive adjustments */
/* Mobile first */

@media (max-width: 768px) {
  .header-container {
    flex-direction: column;
    align-items: center;
  }

  .header-logo img {
    width: 100px !important;
  }

  .nav-links {
    display: none;
    flex-direction: column;
    align-items: center;
  }

  .nav-links.active {
    display: flex;
    background: #3f3f60;
    align-items: flex-start;
    position: absolute;
    width: 100%;
    border-radius: 0 0 20px 20px;
    gap: 15px;
    padding: 20px;
    top: 47px;
    z-index: 1;
  }

  .nav-links.active a {
    width: 100%;
    padding-bottom: 10px;
    border-bottom: 1px solid #474768;
  }

  .logo img {
    width: 150px; /* Smaller logo for mobile */
  }

  .get-quote-btn {
    display: none;
  }

  .hamburger-menu {
    display: block;
    cursor: pointer;
    color: #fff;
    position: absolute;
    right: 20px;
  }
  .banner-header-wrapper {
    height: 500px;
  }
}

@media (min-width: 769px) {
  .hamburger-menu {
    display: none;
  }
}

/* ////////////////////////// banner responsive  ////////////////////////// */

@media (max-width: 768px) {
  .jsa-banner-container {
    height: 350px;
    width: 90%;
  }

  .jsa-banner-content-container h1 {
    font-size: 46px;
    line-height: 1.2em;
  }
}

/* //////////////////////////  About us responsive  ////////////////////////// */

@media (max-width: 768px) {
  .about-header {
    display: flex;
    flex-direction: column;
    font-size: 46px;
  }
  .about-body {
    display: flex;
    flex-direction: column;
  }
  .about-header-services {
    grid-template-columns: 1fr;
    justify-items: center;
  }
  .aboutus-left-img-container {
    height: 300px;
  }
}

/* //////////////////////////  services us responsive  ////////////////////////// */
@media (max-width: 1280px) {
  .services-card-container {
    grid-template-columns: 1fr 1fr;
  }
  .service-card {
    width: 100%;
    height: 300px;
  }
}

@media (max-width: 768px) {
  .services-card-container {
    grid-template-columns: 1fr;
  }

  .service-card {
    width: 100%;
    height: 200px;
  }
}
/* //////////////////////////  Contact us responsive  ////////////////////////// */

@media (max-width: 768px) {
  .contact-section h1 {
    font-size: 46px;
    width: 100%;
  }
}

/* //////////////////////////  Footer responsive  ////////////////////////// */
@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    gap: 0;
  }

  .footer-address {
    margin: 0;
  }
  .footer-contact {
    margin: 0;
  }
}
